.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: 4px;
  background: var(--rc-fltmenu-menu-bg-color, #fff);
  box-shadow: 0 0 25px 8px rgb(0 0 0 / 10%);
  padding-bottom: 1rem;
  position: relative;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@keyframes menu-open-animation {
  0% {
    opacity: 0;
    height: 0;
    visibility: hidden;
    overflow: hidden;
  }

  100% {
    opacity: 1;
    height: var(--menu-height, auto);
    visibility: visible;
    overflow: hidden;
  }
}

@keyframes menu-close-animation {
  0% {
    opacity: 1;
    height: var(--menu-height, auto);
    visibility: visible;
    overflow: hidden;
  }

  100% {
    opacity: 0;
    height: 0;
    visibility: hidden;
    overflow: hidden;
  }
}

.menu_open {
  &:not(.no_animation) {
    visibility: visible;
    animation: menu-open-animation 0.5s ease-in-out;
  }

  height: var(--menu-height, auto);
}

.menu_close:not(.no_animation) {
  visibility: hidden;
  animation: menu-close-animation 0.5s ease-in-out;
  height: 0;
}

.hide {
  visibility: hidden;
}

.close_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  width: 28px;
  height: 28px;
  z-index: 1;
  background: #f5f5f5;
  border-radius: 4px;
  cursor: pointer;
  color: var(--rc-fltmenu-primary);

  &.flip {
    margin-left: 4px;
  }

  &:not(.flip) {
    margin-right: 4px;
  }

  &:focus {
    outline: 2px solid var(--rc-fltmenu-primary);
    outline-offset: 1px;
  }

  &:hover {
    background: var(--rc-fltmenu-primary);
    color: #fff;
  }

  svg {
    width: 80%;
    height: 80%;
  }
}

.toolbar {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  width: 100%;

  &.flip {
    justify-content: flex-start;
  }

  &:not(.flip) {
    justify-content: flex-end;
  }
}
