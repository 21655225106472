.list_item {
  width: 100%;
  padding: 0.75rem 0;
  cursor: pointer;
  align-self: center;
  position: relative;
  touch-action: none;
  user-select: none;

  &.rtl {
    direction: rtl;
  }

  &.no_icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &.icon {
    display: grid;
    grid-template-columns: calc(var(--rc-fltmenu-icon-size) * 2) auto;
    justify-items: flex-start;
    align-items: center;
  }

  &:hover {
    background: var(--rc-fltmenu-menu-item-hover, #f5f5f5);
  }

  & > span:not(.list_item_name) {
    color: var(--rc-fltmenu-primary);
  }

  &:hover > span {
    color: var(--rc-fltmenu-menu-item-hover-text, --rc-fltmenu-primary);
  }

  &:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }

  &:focus {
    outline: 1px solid var(--rc-fltmenu-primary);
    outline-offset: 2px;
    outline-width: 2px;
  }
}

.list_item_name {
  display: flex;
  align-items: center;
  color: var(--rc-fltmenu-menu-item-text, #000);

  &.no_icon:not(.rtl) {
    padding-left: var(--rc-fltmenu-icon-size, 1rem);
  }

  &.no_icon.rtl {
    padding-right: var(--rc-fltmenu-icon-size, 1rem);
  }
}

.list_item_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--rc-fltmenu-icon-size, 1rem);
  height: var(--rc-fltmenu-icon-size, 1rem);
  justify-self: center;

  svg {
    width: 100%;
    height: 100%;
  }
}

.child_menu_wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: 0;

  &.menu_flip {
    left: -101%;
  }

  &:not(.menu_flip) {
    left: 101%;
  }
}

.chevron {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  height: 1rem;
  width: 1rem;

  svg {
    width: 100%;
    height: 100%;
  }
}

.chevron_right {
  composes: chevron;
  right: 0.5rem;
  transform: translateY(-50%);
}

.chevron_left {
  composes: chevron;
  left: 0.5rem;
  transform: translateY(-50%) rotate(180deg);
}
