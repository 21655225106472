.menu_container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  width: var(--rc-fltmenu-width, 250px);
  z-index: 99999;

  &.open {
    visibility: visible;
  }

  &.close {
    visibility: hidden;
  }
}

.menu_arrow {
  display: flex;
  height: 10px;
  align-items: center;
  justify-content: center;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  &.menu_open:not(.flip)::after {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--rc-fltmenu-menu-bg-color, #fff);
    top: -10px;
  }

  &.menu_open.flip::after {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--rc-fltmenu-menu-bg-color, #fff);
    bottom: -10px;
  }
}
