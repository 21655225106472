.menu_head {
  align-items: center;
  background: var(--rc-fltmenu-primary);
  display: flex;
  height: var(--dimension);
  justify-content: center;
  width: var(--dimension);
  cursor: pointer;
  user-select: none;
  z-index: 99999;

  &:not(.is_dragged) {
    transition: left 0.1s ease, top 0.1s ease;
  }

  &.circle {
    border-radius: 50%;
  }

  &.square {
    border-radius: 4px;
  }

  &:focus {
    outline: 1px solid var(--rc-fltmenu-primary);
    outline-offset: 3px;
    outline-width: 2px;
  }
}

@keyframes pressed-animation {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.75);
  }
}

@keyframes released-animation {
  0% {
    transform: scale(0.75);
  }

  100% {
    transform: scale(1);
  }
}

.pressed {
  animation: pressed-animation 0.2s ease-in-out;
  transform: scale(0.75);
}

.released {
  animation: released-animation 0.2s ease-in-out;
  transform: scale(1);
}

.icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  svg {
    width: 80%;
    height: 80%;
  }
}
